<template>
  <EntityList
    :config="config"
    :columns="columns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :formatted-columns="formattedColumns"
    authority="Congress_Management_Congress"
    single-edit
    desc-sort
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import store from '@/store'
import storeModule from '../CongressStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      categoryOptions: [],
      statusOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'congress',
        endpoint: 'congress',
        route: 'congress',
        title: {
          single: this.$t('Congress Add'),
          plural: this.$t('Congresses'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('Title'), sortable: true },
        { key: 'status.title', label: this.$t('Status'), sortable: false },
        { key: 'startDate', label: this.$t('Start Date'), sortable: false },
        { key: 'endDate', label: this.$t('End Date'), sortable: false },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'startDate', type: 'date' },
        { key: 'endDate', type: 'date' },
      ]
    },

    filters() {
      return [
        {
          name: 'category',
          label: this.$t('Category'),
          options: this.categoryOptions,
        },
        {
          name: 'startDate',
          type: 'date',
          label: this.$t('Start Date'),
          maxDateFieldIdx: '2',
        },
        {
          name: 'endDate',
          type: 'date',
          label: this.$t('End Date'),
          minDateFieldIdx: '1',
        },
        {
          name: 'status',
          label: this.$t('Status'),
          options: this.statusOptions,
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
        },
        {
          name: 'featured',
          type: 'boolean',
          label: this.$t('Featured'),
        },
      ]
    },
  },
  mounted() {
    store.dispatch('app-common/fetchCongressCategories').then(response => {
      this.categoryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCongressStatuses').then(response => {
      this.statusOptions = response.data
    })
  },
}
</script>
